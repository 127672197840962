<template>
  <main class="summary-page">
    <Heading :lineOnly="true" />
    <section class="summary step step--summary">
      <div class="summary__inner">
        <p class="summary__title" data-aos="fade-in">Dziękujemy za przesłanie formularza</p>
        <div data-aos="fade-in" data-aos-delay="250">
          <p class="summary__desc">
            W dni robocze skontaktujemy się z Państwem w ciągu najbliższych 48 godzin.
          </p>
          <p class="summary__desc">
            Nasi doświadczeni Partnerzy służą wiedzą i doradztwem bezpośrednio.
          </p>
        </div>
        <div class="summary__buttons" data-aos="fade-in" data-aos-delay="500">
          <Button url="/" :title="'Chcę złożyć kolejne zapytanie'" :classes="'e-button--primary'" />
          <Button url="https://dobroplast.pl/" :title="'Przejdź do sklepu Dobroplast'" :classes="'e-button--primary'" />
        </div>
        <!-- / summary__buttons -->

      </div>
      <!-- / summary__inner -->

    </section>
    <!-- / summary -->
  </main>
</template>

<script>
import Button from "@/components/Button.vue";
import Heading from "@/components/Heading.vue";

export default {
  name: "Summary",
  components: {
    Button, Heading
  },
  computed: {
  },
  beforeRouteLeave (to, from, next) {
    return;
  }
};
</script>

<style scoped lang="scss">
.summary {
  background-color: $color-bg-alt;
  color: $color-text;

  &__title {
    @extend %summary-title;
    margin-bottom: 30px;
  }

  &__inner {
    margin: 0 auto;
    max-width: 450px;
  }

  &__desc {
    @extend %product-name;
    margin-bottom: 20px;

    &--highlight {
      color: $color-primary;
      font-weight: 700;
    }
  }

  &__buttons {
    @include flex(flex-start, stretch, nowrap);
    flex-direction: column;
    margin-top: 40px;

    @media #{$lg} {
      margin-top: 50px;
    }

    .e-button {
      &:not(:first-of-type) {
        margin-top: 20px;

        @media #{$lg} {
          margin-top: 26px;
        }
      }
    }
  }
}
</style>

